<template>
	<h1>Terms &amp; Conditions</h1>
	<p>
		Welcome to Arthur, an online service that enables you to create, organize,
		share and discover collections of the art you love.
	</p>
	<p>
		The following terms and conditions ("Terms") govern your use of the Arthur
		website and platform, located at <em>arthur.io</em>, and your use of any and
		all products, computer programs, applications, widgets, services, platforms,
		integrations and web sites hosted or made available by Arthur, as well as
		any images, illustrations, photographs, graphics, logos, audio, video,
		music, text, user comments, messages, information, data, articles and any
		other content displayed on or accessed through the Arthur platform,
		regardless of whether accessed through the Arthur website or a third-party
		platform (collectively, the "Service" or "Services").
	</p>
	<p>
		These Terms, along with our Privacy Policy and Copyright Policy, create a
		legal contract between you and Arthur regarding your use of the Services.
		Throughout these Terms, "Arthur" "us" "our" and "we" refers to Arthur and
		its affiliates; and "you" or "User" refers to you, our user — anyone who
		uses, joins, visits, subscribes to or otherwise accesses Arthur and its
		Services.
		<!-- Users who would like to market art galleries, agencies, dealers and other businesses through Arthur must also enter into our Art Shark Agreement [link], which outlines the terms of usage for businesses using Arthur’s tools and resources for businesses. -->
	</p>
	<p>
		Your access to the Services is subject to the Terms outlined here and
		subject to change, suspension, modification or discontinuation at any time,
		and we reserve the right to update and change these Terms by posting updates
		and changes to this website. Any new features or tools which are added to
		the current Service shall be also subject to the Terms of Service. You are
		advised to check the Terms of Service from time to time for any updates or
		changes that may impact you.
	</p>

	<h3>Description of Service</h3>
	<p>
		Arthur is an online service that enables users to create, organize, share
		and discover digital collections of art. Through the Service, Users may
		bookmark digital images and assemble collections of artwork, as well as
		follow and interact with other users, artists, galleries, agents, and
		websites that create, sell, promote, and/or distribute works of art.
	</p>

	<h3>Content Rights</h3>
	<p>
		Our Service is used to display, share, and otherwise make available photos,
		text and any other material uploaded by Users to the Arthur platform
		(collectively, "User Content"), and to allow Users to interact with and
		discover User Content across the Service. As a user of our Service, you
		represent and warrant to us that the underlying intellectual property rights
		to the material you post, display, transmit or otherwise make available on
		Arthur, including but not limited to copyrights, are owned by the you, the
		User who has posted the material.
	</p>
	<p>
		You, the User, shall retain all rights to the Content that you post to the
		Service. You hereby grant us a non-exclusive, worldwide, royalty-free right
		and license to reproduce, distribute, publicly display, and otherwise
		exploit any User Content you upload to the Service and any material
		associated with your User Content and User profile, including but not
		limited to photographs, images, likenesses, names, artwork,
		logos/trademarks, and audio, music or audio-visual recordings for the
		purposes of operating and providing our Services to you and other Users.
		Additionally, to the extent permissible under the laws of your state,
		jurisdiction or country, you hereby waive any right of droit moral or any
		similar right with respect to the User Content and agree not to institute,
		support or maintain any action or lawsuit on the ground that the exercise of
		any of the rights conveyed by you under these Terms constitutes an
		infringement of any right of droit moral or any similar right is in any way
		a defamation or mutilation of the User Content, or any part thereof, or of
		the reputation of any artist, or contains unauthorized variations,
		alterations, modifications, changes or translations.
	</p>
	<p>
		We take intellectual property rights seriously. Although we do not select or
		control the content shared through the Arthur platform, we make it our top
		priority to remove infringing, defamatory, offensive and/or abusive content
		upon request. You can visit our Copyright and DMCA Policy page to learn more
		about how we deal with copyrighted material and to read about Arthur’s
		compliance with the Digital Millennium Copyright Act.
	</p>
	<p>
		If you notify us of content that infringes your intellectual property rights
		or the intellectual property rights of a third party, or content that
		violates our policies, we will review your request and block infringing
		content from Arthur within seven (7) business days.
	</p>

	<h3>Our Intellectual Property</h3>
	<p>
		We own and retain all right, title and interest in and to the Service, and
		all related technology, materials, data, tools, widgets, user activity
		reports, intellectual property, programming, development and design,
		including but not limited to the front and backend systems, visual design,
		Internet website and accompanying databases. Notwithstanding material posted
		or uploaded by our Users, all of the content on the Service, including
		without limitation, the Arthur logo, and all designs, text, graphics,
		pictures, selection, coordination, 'look and feel', information, data,
		software, sound files, other files and the selection and arrangement thereof
		(collectively, the "Site Materials"), are owned by or licensed to Arthur. We
		protect our Site Materials to the fullest extent permissible under United
		States and international trade dress, copyright, patent and trademark laws,
		and various other intellectual property and unfair competition laws. This
		Agreement does not transfer any ownership rights in any of the foregoing to
		you or any third party.
	</p>
	<p>
		Arthur is a registered trademark owned by Arthur, and may not be copied,
		imitated or used, in whole or in part, without the prior written permission
		of Arthur You may not use any metatags or any other "hidden text" utilizing
		"Arthur" or any other name, trademark or product or service name of "Arthur"
		without our prior written permission. Content on the website is provided to
		you on an "as is" basis for your information and personal use only and may
		not be copied, reproduced, distributed, transmitted, displayed, publicly
		performed, sold, licensed, or otherwise exploited for any other purposes
		whatsoever without the prior written consent of the respective rights
		holders. All other trademarks, registered trademarks, product names and
		names or logos mentioned in the Service are the property of their respective
		owners.
	</p>

	<h3>Usage Policies</h3>
	<p>
		We created Arthur out of a shared love for art, and we hope that our Service
		will enable our users to connect with the creative community worldwide. To
		this end, we ask you to treat other Users with respect and consideration,
		keeping in mind that Arthur’s User community is, like the Internet, a global
		one, and to respect the rights of third party creators and content owners.
	</p>
	<p>
		In connection with the User Content you post, you represent, warrant and
		agree that you own or have all licenses and rights to use and to authorize
		Arthur to enable, use, display, and distribute the material you have posted
		to the Service. We reserve the right to remove any material you post,
		deactivate any account, and block any users from the Service at any time and
		for any reason.
	</p>
	<p>
		Arthur is offered to you on a non-exclusive basis for your sole personal,
		non-commercial use. You may access the Services solely to find information
		about, preview , comment upon, and share content posted by Users, as
		intended through the normal functionality of the Service. To use Arthur, you
		must be an individual (i.e., not a corporation) and have reached the age of
		majority in the state, jurisdiction, or country in which you reside, be
		fully able and competent to enter into and abide by the terms, conditions,
		obligations, affirmations, representations and warranties set forth in these
		Terms, and be able to abide by and comply with these Terms. In no event may
		anyone under the age of 18 use Arthur.
	</p>
	<p>
		You may not use the Service for any illegal or unauthorized purposes. You
		may not use the Service in a way that could damage its content or impair its
		operation in any way. You agree not to access, or attempt to access, any of
		the Services by any means other than through the interface that is provided
		by Arthur, unless you have been specifically allowed to do so in a separate
		agreement by Arthur. You specifically agree not to access, or attempt to
		access, any of the Services through any automated means, including use of
		scripts or bots. You also understand that by using the Service you may be
		exposed to content created by third parties, including but not limited to
		content created by advertisers, or content that is offensive, indecent or
		objectionable.
	</p>
	<p>
		You hereby agree to comply with all applicable local rules regarding online
		conduct and acceptable content. Specifically you agree to comply with all
		applicable laws regarding the transmission of technical data from the
		country in which you reside. We may remove content or accounts containing
		content that we determine in our sole discretion are unlawful, offensive,
		threatening, libelous, defamatory, obscene or otherwise objectionable or
		violative of a third party’s intellectual property or other legal rights.
	</p>

	<h3>User Accounts</h3>
	<p>
		In order to access the features of the site, you must create and/or log into
		a Arthur account of your own. Use of another’s account is not permitted.
		When creating your account, you must provide accurate and complete
		information.
	</p>
	<p>
		You are solely responsible for the activity that occurs in your account. By
		connecting Arthur with a third-party service, you give us permission to
		access and use your information from that service as permitted by that
		service (e.g., Facebook and Twitter). You are also responsible for
		maintaining the security of your account password, as well as the passwords
		of any third-party services that you may have elected to link to your
		account.
	</p>
	<p>
		You agree to notify Arthur immediately of any breach of security or
		unauthorized use of your account. You may be liable for the losses of Arthur
		or others due to such unauthorized use or any other use in violation of
		these Terms. Arthur reserves the right to reclaim usernames on behalf of
		businesses or individuals that hold legal claim or trademark on those
		usernames.
	</p>
	<p>
		You are solely responsible for your interactions with other Users. We
		reserve the right, but have no obligation, to monitor disputes between you
		and other Users that may arise. Arthur will not be liable for your
		interactions with other Users, or for any User’s action or inaction.
	</p>
	<p>
		Please review our Privacy Policy page for information regarding security,
		confidentiality, and what we do with the information you provide us.
	</p>

	<h3>Limitations of Liability</h3>
	<p>
		The Service provides content uploaded, transmitted or otherwise shared by
		our users. Because we have no control over such content, you acknowledge and
		agree that Arthur is not responsible for and does not assume responsibility
		or accept liability for any third-party websites, services, files, content,
		advertising, products or other materials on or made available by third
		parties through its platform. You further acknowledge and agree that we
		shall not be responsible for or liable, directly or indirectly, for any
		damage or loss caused or alleged to be caused by or in connection with use
		of or reliance on any such artwork, video, content, galleries and
		collections or services available on or through any such site or resource.
	</p>
	<p>
		You expressly understand and agree that Arthur and its subsidiaries,
		affiliates, officers, agents, employees, partners and licensors shall not be
		liable to you for any direct, indirect, incidental, special, consequential
		or exemplary damages, including but not limited to, damages for losses (even
		if we have been advised of the possibility of such damages), resulting from:
	</p>
	<p>
		- The use or the inability to use the Service;<br />
		- Unauthorized access to or alteration of your transmissions or data;<br />
		- Statements or conduct of any third party on the Service; or<br />
		- Any other matter relating to the Service
	</p>

	<h3>Disclaimer of Warranties</h3>
	<p>You expressly understand and agree that:</p>
	<p>
		a) your use of the of the Service is at your discretion and risk. We
		disclaim any responsibility for the deletion, the failure to store, or
		failed or untimely delivery of any information or material. We disclaim any
		responsibility for any harm resulting from accessing information or material
		on the Internet using our Service. The Service is provided on an "as is" and
		"as available" basis. Arthur and its subsidiaries, affiliates, officers,
		employees, agents, partners and licensors expressly disclaim all warranties
		of any kind, whether express or implied, including, but not limited to the
		implied warranties of merchantability, fitness for a particular purpose and
		non-infringement.<br />
		b) Arthur and its subsidiaries, affiliates, officers, employees , agents,
		partners and licensors make no warranty that the Service will meet your
		requirements; the Service will be uninterrupted, timely, secure or
		error-free; the result that may be obtained from the use of the Service will
		be accurate or reliable; the quality of any products, services, information
		or other material purchased or obtained by you through the Service will meet
		your expectations; or that any errors in the software will be corrected.<br />
		c) Any material downloaded or otherwise obtained through the use of the
		Arthur is accessed at your own discretion and risk, and you will be solely
		responsible for any damage to your computer system or loss of data that
		results from m the download of any such material<br />
		d) We hereby disclaim all warranties, express or implied, with respect to
		Arthur including, without limitation, any warranty of merchantability or
		fitness for a particular use. No advice or information, whether oral or
		written, obtained by you from Arthur or through or from the Service shall
		create any warranty not expressly stated in the Terms.
	</p>

	<h3>Indemnification</h3>
	<p>
		You agree to indemnify and hold Arthur and its subsidiaries, affiliates,
		officers, agents, employees, partners and licensors harmless from any
		claims, losses, damages and expenses, including court costs and reasonable
		attorney’s fees, made by any third party due to or arising out of links you
		submit, post, transmit or otherwise make available through the Service, your
		use of the Service, your connection to the Service, your violation of the
		Terms of Service, or your violation of any rights of another party.
	</p>

	<h3>Miscellaneous</h3>
	<p>
		These Terms, along with our Privacy Policy and Copyright and DMCA Policy,
		constitute the entire Agreement between you and Arthur pertaining to the
		Services, and may not be changed, waived or terminated by you except by an
		instrument in writing signed by you and Arthur We shall have the right to
		assign this Agreement at any time to any person or entity. The Terms are
		binding upon and shall inure to the benefit of our respective successors
		and/or assigns.
	</p>
	<p>
		In the event a dispute shall arise between the parties to these Terms, the
		parties agree to submit to binding arbitration in accordance with the
		mediation procedures of United States Arbitration & Mediation in the borough
		of Manhattan in the State of New York. The parties agree to share equally in
		the costs of the arbitration.
	</p>
	<p>
		This Agreement shall be governed by and construed in accordance with the
		laws of the State of New York, without regard to the conflicts of laws
		principles thereof. The parties hereby consent to the exclusive jurisdiction
		of and venue in the federal and state courts located in the State of New
		York.
	</p>

	<h3>Revision of Terms</h3>
	<p>
		We reserve the right to revise this policy at any time, including by posting
		a new version at this website. You shall be responsible for reviewing and
		becoming familiar with any such modifications. Your use of the Services
		following such notification constitutes your acceptance of the terms and
		conditions of this Agreement as modified.
	</p>
</template>

<script>
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'LegalTerms',
	setup() {
		injectMetaData({
			title: 'Arthur.io • Terms of Service',
		})
	},
}
</script>
